<template>
    <div>
        <b-button class="ml-2" variant="primary btn-mw" size="lg" v-b-modal.singletransfer>Pay or Transfer</b-button>
        <!-- Start New Transfer Modal -->
        <b-modal id="singletransfer" title="Start New Transfer" no-stacking hide-footer>
            <div class="modal-area">
                <ul class="list-unstyled mb-0 text-center payment-list">
                    <li class="border rounded p-3" v-b-modal.sending_money>
                        <div class="btn btn-primary btn-lg mb-2 font-0">
                            <i class="fa fa-paper-plane"></i>
                        </div>
                        <a href="#"><h6 class="mb-0 text-dark">Single Payment or Transfer</h6></a>
                        <p>send money to one beneficiary only</p>
                    </li>
                    <li class="border rounded p-3">
                        <div class="btn btn-primary btn-lg mb-2 font-0">
                            <svg class="payment svg-icon" x="0px" y="0px" viewBox="0 0 100 100" xml:space="preserve">
                                <path d="M11.5,51c0-1.5,1.2-2.7,2.7-2.7h64.1L61.2,31.2c-1.1-1.1-1.1-2.8,0-3.8c1.1-1.1,2.8-1.1,3.8,0l21.7,21.7
                                    c1.1,1.1,1.1,2.8,0,3.8c0,0,0,0,0,0L65,74.7c-1.1,1.1-2.8,1.1-3.8,0s-1.1-2.8,0-3.8l17.1-17.1H14.2C12.7,53.7,11.5,52.5,11.5,51z"
                                    />
                                <path d="M8,50c0,23.2,18.8,42,42,42s42-18.8,42-42S73.2,8,50,8S8,26.8,8,50z M98,50c0,26.5-21.5,48-48,48S2,76.5,2,50S23.5,2,50,2
                                    S98,23.5,98,50z M24.7,26c-1,0-1.8,0.8-1.8,1.8c0,1,0.8,1.8,1.8,1.8h22.7l-7.7,7.7c-0.7,0.7-0.7,1.8,0,2.5c0.7,0.7,1.8,0.7,2.5,0
                                    L53,29.1c0.7-0.7,0.7-1.8,0-2.5c0,0,0,0,0,0L42.3,15.8c-0.7-0.7-1.8-0.7-2.5,0c-0.7,0.7-0.7,1.8,0,2.5l7.7,7.7H24.7z M24.7,70
                                    c-1,0-1.8,0.8-1.8,1.8c0,1,0.8,1.8,1.8,1.8h22.7l-7.7,7.7c-0.7,0.7-0.7,1.8,0,2.5c0.7,0.7,1.8,0.7,2.5,0L53,73.1
                                    c0.7-0.7,0.7-1.8,0-2.5c0,0,0,0,0,0L42.3,59.8c-0.7-0.7-1.8-0.7-2.5,0c-0.7,0.7-0.7,1.8,0,2.5l7.7,7.7H24.7z"/>
                            </svg>
                        </div>
                        <a href="#"><h6 class="mb-0 text-dark">Multi Payments or Transfers</h6></a>
                        <p>send money to more than one beneficiary at a go</p>
                    </li>
                    <li class="border rounded p-3">
                        <div class="btn btn-primary btn-lg mb-2 font-0">
                            <svg class="payment svg-icon" x="0px" y="0px" viewBox="0 0 100 100" xml:space="preserve">
                                <path d="M20.9,20.1h19.5V14h-7.8c0,0,27.5-14.1,49.6,16l5.6-3.5c0,0-21-35.3-60.2-17.7V1h-6.8V20.1z"/>
                                <path d="M34.5,89.3l-9.9-16.8l-5.2,3.1l4,6.7c0,0-26.1-16.5-11.4-50.8L6,28.4c0,0-19.7,36,15.4,60.8l-6.8,4l3.4,5.8L34.5,89.3z"/>
                                <path d="M82.9,42.5l-9.6,17l5.3,3l3.8-6.8c0,0-1.3,30.9-38.4,35.2l0.2,6.6c0,0,41.1-0.8,45.1-43.6l6.8,3.9l3.3-5.9L82.9,42.5z"/>
                            </svg>
                        </div>
                        <a href="#"><h6 class="mb-0 text-dark">Bulk Payment or Transfers</h6></a>
                        <p>upload multiple lines of transfer instructions for payment</p>
                    </li>
                </ul>
            </div>
        </b-modal>

        <!-- Sending Money model -->
        <b-modal id="sending_money" title="Who are you sending money to ?" no-stacking hide-footer>
            <div class="modal-area">
                <ul class="list-unstyled send-money-list mb-0">
                    <li v-b-modal.single_transfer>
                        <div class="d-flex align-items-center">
                            <div class="left">
                                <i class="fa fa-street-view"></i>
                            </div>
                            <div class="center">
                                <h5>Myself</h5>
                            </div>
                            <div class="right ml-auto">
                                <i class="fa fa-chevron-right"></i>
                            </div>
                        </div>
                    </li>
                    <li v-b-modal.single_transfer>
                        <div class="d-flex align-items-center mt-4">
                            <div class="left">
                                <i class="fa fa-user"></i>
                            </div>
                            <div class="center">
                                <h5>Another Person</h5>
                            </div>
                            <div class="right ml-auto">
                                <i class="fa fa-chevron-right"></i>
                            </div>
                        </div>
                    </li>
                    <li v-b-modal.single_transfer>
                        <div class="d-flex align-items-center mt-4">
                            <div class="left">
                                <i class="fa fa-university"></i>
                            </div>
                            <div class="center">
                                    <h5>A Business / Organization</h5>
                            </div>
                            <div class="right ml-auto">
                                <i class="fa fa-chevron-right"></i>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </b-modal>

        <!-- Single Transfer Modal -->
        <b-modal id="single_transfer" title="Single Transfer"  no-stacking hide-footer>
            <div class="modal-area">
                <ul class="list-unstyled mb-0 payment-single-transfer">
                    <ValidationObserver v-slot="{ passes }">
                        <form  id="singletransfer-form" @submit.prevent="passes(singleTransfer)">
                            <li>
                                <ValidationProvider name="balance" rules="required" v-slot="{ errors}">
                                    <div class="form-group form-select single-multiselect" :class="{'errors': errors.length && !user.balance,'success':user.balance}">
                                        <span class="float-label">
                                            <label for="functions">Choose balance to transfer from*</label>
                                            <multiselect
                                                placeholder=""
                                                v-model="user.balance"  
                                                :show-labels="false" 
                                                :options="balance_options" 
                                                :searchable="false"
                                                label="name" 
                                                track-by="name" >
                                                <template slot="option" slot-scope="props">
                                                    <div class="option-title">
                                                        <span class="mr-2" :class="props.option.flagClass"></span>
                                                        <span>{{ props.option.name }}</span>
                                                    </div>
                                                </template>
                                            </multiselect> 
                                            <i class="fa fa-times" v-if="errors.length && !user.balance"></i>
                                            <i class="fa fa-check" v-if="user.balance"></i>
                                        </span>
                                    </div>
                                </ValidationProvider>
                            </li>
                            <li>
                                <div class="phone-with-country d-flex justify-content-between align-items-center">
                                    <div class="select-country">
                                        <ValidationProvider name="currency" rules="required" v-slot="{ errors }">
                                            <div class="form-group form-select single-multiselect" :class="{'errors': errors[0],'success':user.currency != null  && !errors[0]}">
                                                <span class="float-label">
                                                <label for="functions">How much do you want to send ?*</label>
                                                    <multiselect
                                                        placeholder=""
                                                        v-model="user.currency"   
                                                        :show-labels="false" 
                                                        :options="listof_currency"
                                                        :searchable="false"
                                                        label="name" 
                                                        track-by="name" >
                                                        <template slot="option" slot-scope="props">
                                                            <div class="option-title">
                                                                <span class="mr-2" :class="props.option.flagClass"></span>
                                                                <span>{{ props.option.name }}</span>
                                                            </div>
                                                        </template>
                                                    </multiselect> 
                                                    <i class="fa fa-times" v-if="errors.length && !user.currency"></i>
                                                    <i class="fa fa-check" v-if="user.currency"></i>
                                                </span>
                                            </div>
                                        </ValidationProvider>
                                    </div>
                                    <div class="add-number">
                                        <ValidationProvider name="sendBalanceAmount" rules="required" v-slot="{ errors}">
                                            <div class="form-group" :class="{'errors': errors.length && !user.sendBalanceAmount,'success':user.sendBalanceAmount}">
                                                <span class="float-label">
                                                    <input type="text" v-model="user.sendBalanceAmount" class="form-control" id="sendBalanceAmount" placeholder="0.00">
                                                    <label for="sendBalanceAmount" class="control-label">Send Amount*</label>
                                                    <div class="line"></div>
                                                    <i class="fa fa-times" v-if="errors.length && !user.sendBalanceAmount"></i>
                                                    <i class="fa fa-check" v-if="user.sendBalanceAmount"></i>
                                                </span>
                                            </div>
                                        </ValidationProvider>
                                    </div>
                                </div>
                            </li>
                            <li>   
                                <ValidationProvider name="bank_name" rules="required" v-slot="{ errors}">                                             
                                    <div class="form-group form-select single-multiselect" :class="{'errors': errors.length && !user.bank_name,'success':user.bank_name}">
                                        <span class="float-label">
                                            <label for="functions">Bank name*</label>
                                            <multiselect :searchable="false" v-model="user.bank_name" :options="bank_name_options" placeholder=""></multiselect>
                                            <i class="fa fa-times" v-if="errors.length && !user.bank_name"></i>
                                            <i class="fa fa-check" v-if="user.bank_name"></i>
                                        </span>
                                    </div>
                                </ValidationProvider>
                            </li>
                            <li>
                                <ValidationProvider name="accountNumber" rules="required" v-slot="{ errors}"> 
                                    <div class="form-group" :class="{'errors': errors.length && !user.accountNumber,'success':user.accountNumber}">
                                        <span class="float-label">
                                            <input type="text" v-model="user.accountNumber" class="form-control" id="accountNumber" placeholder="Account Number*" @blur="accountFisEmpty">
                                            <label for="accountNumber" class="control-label">Account Number*</label>
                                            <div class="line"></div>
                                            <i class="fa fa-times" v-if="errors.length && !user.accountNumber"></i>
                                            <i class="fa fa-check" v-if="user.accountNumber"></i>
                                        </span>
                                    </div>
                                </ValidationProvider>
                            </li>
                            <li>
                                <ValidationProvider name="beneficiary_name" rules="required" v-slot="{ errors}"> 
                                    <div class="form-group" :class="{'errors': errors.length && !user.beneficiary_name,'success':user.beneficiary_name}">
                                        <span class="float-label">
                                            <input type="text" v-model="user.beneficiary_name" class="form-control" id="beneficiary_name" placeholder="Beneficiary Name*">
                                            <label for="beneficiary_name" class="control-label">Beneficiary Name*</label>
                                            <div class="line"></div>
                                            <i class="fa fa-times" v-if="errors.length && !user.beneficiary_name"></i>
                                            <i class="fa fa-check" v-if="user.beneficiary_name"></i>
                                        </span>
                                    </div>
                                </ValidationProvider>
                            </li>
                            <li class="or-account">
                                <a href="javascript:void(0);">Or select an existing recipient</a>
                            </li>
                            <li>
                                <div class="form-group">
                                    <span class="float-label">
                                        <input type="text" class="form-control" id="description" placeholder="Optional">
                                        <label for="description" class="control-label">Description</label>
                                        <div class="line"></div>
                                    </span>
                                </div>
                            </li>
                            <li>
                                <i class="fa fa-info-circle"></i> You will be changed GHS 0.00 for this transaction
                            </li>
                            <li>
                                <div class="d-flex justify-content-between align-items-center w-100 mt-5">
                                    <div>
                                        <a href="#" v-b-modal.sending_money><i class="fa fa-angle-left"></i> Go back</a>
                                    </div>
                                    <div>
                                        <b-button @click="$bvModal.hide('single_transfer')" size="lg">Cancel</b-button>
                                        <b-button type="submit" variant="primary ml-1" size="lg">Confirm Transfer</b-button>
                                    </div>
                                </div>
                            </li>
                        </form>
                    </ValidationObserver>
                </ul>
            </div>
        </b-modal>

        <!-- Account Invalid Modal -->
        <b-modal id="invalideAccoubtNumber" ref="invalideAccoubtNumber" hide-footer> 
            <div class="modal-area">
                <ul class="list-unstyled mb-0 invalid-modal text-center px-2">
                    <li><i class="fa fa-4x fa-warning text-danger mt-4"></i></li>
                    <li><h4 class="mt-5 mb-3">Account name doesn't match</h4></li>
                    <li><p class="mb-4">The recipient's bank told us the name you entered is not the name on the account.please double check the details and only continue if you're sure the recipient is trustworthly.</p></li>
                    <li><b-button class="mb-3" block variant="primary" size="lg" @click="$bvModal.hide('invalideAccoubtNumber')">Edit account details</b-button></li>
                    <li><a href="#" @click="$bvModal.hide('invalideAccoubtNumber')">Keep what i entered</a></li>
                </ul>
            </div>
        </b-modal>

        <!-- Payment Notification Modal -->
        <b-modal id="payment_notification" title="Payment notification" ref="payment_notification" hide-footer no-stacking>
            <div class="modal-area">
                <ul class="list-unstyled mb-0 invalid-modal text-center px-2">
                    <li><p>Add recipient's email and we can automatically notify them for you when you pay them</p></li>
                    <ValidationObserver v-slot="{ passes }">
                        <form @submit.prevent="passes(paymentTransfer)" id="payment-notification-form">
                            <li>
                                <ValidationProvider name="payment_email" rules="required|email" v-slot="{ errors}">
                                    <div class="form-group" :class="{'errors': errors.length && !payment.payment_email || errors[0],'success':payment.payment_email && !errors[0]}">
                                        <span class="float-label">
                                            <input type="email" v-model="payment.payment_email" class="form-control" name="email1" id="payment_email" placeholder="Recipient email*">
                                            <label for="payment_email" class="control-label">Recipient email*</label>
                                            <div class="line"></div>
                                            <i class="fa fa-times" v-if="errors.length && !payment.payment_email || errors[0] "></i>
                                            <i class="fa fa-check" v-if="payment.payment_email && !errors[0]"></i>
                                        </span>
                                    </div>
                                </ValidationProvider>
                            </li>
                            <li><b-button block type="submit" class="mb-3 mt-5 btn-lg" variant="primary">Continue</b-button></li>
                            <li><a href="#" @click="$bvModal.show('addmoney')">Not now</a></li>
                        </form>
                    </ValidationObserver>
                </ul>
            </div>
        </b-modal>

        <!-- Add Money Modal -->
        <b-modal id="addmoney" ref="addmoney" hide-footer no-stacking>
            <template #modal-title>
                How to add money
                <small class="d-block"><i class="fa fa-lock" aria-hidden="true"></i> All your details are saved securely</small>
            </template>
            <div class="modal-area">
                <ul class="list-unstyled mb-0">
                    <li>
                        <div class="d-flex justify-content-between align-items-center mb-3">
                            <p class="mb-0">Linked accounts</p>
                            <a href="javascript:void(0);">Add account</a>
                        </div>
                    </li>
                    <li>
                        <b-card class="shadow-all">
                            <p class="mb-0 font-16">
                                <i class="fa fa-home mr-3" aria-hidden="true"></i> No account linked yet
                            </p>
                        </b-card>
                    </li>
                    <li>
                        <label>Transfers</label>
                    </li>
                    <li>
                        <b-card class="transfers-list shadow-all">
                            <div class="d-flex align-items-center">
                                <div class="left">
                                    <i class="fa fa-exchange" aria-hidden="true"></i>
                                </div>
                                <div class="right">
                                    <strong class="font-16">Easy bank transfer</strong>
                                    <p>Easy and secure without entering account details</p>
                                </div>
                            </div>
                            <div class="d-flex align-items-center">
                                <div class="left">
                                    <i class="fa fa-home" aria-hidden="true"></i>
                                </div>
                                <div class="right">
                                    <strong class="font-16">Transfer using bank details</strong>
                                    <p>Find your Revolut account details</p>
                                </div>
                            </div>
                        </b-card>
                    </li>
                </ul>
            </div>
        </b-modal>
    </div>
</template>
<script>
const { ValidationObserver,ValidationProvider} = require('vee-validate')
import "@/plugins/vee-validate";
export default {
    name: 'PayorTransferModel',
    components: {
        ValidationObserver,
        ValidationProvider,
        Multiselect: () => import('vue-multiselect')
    },data (){
        return{
            user: {
                balance: null,
                currency: null,
                sendBalanceAmount: '',
                accountNumber:'',
                bank_name:null,
                beneficiary_name:null,
            },

            balance_options: [
                { name: 'Ghanaian Cedi', flagClass: "flag-icon flag-icon-gh fi-xl fi-round"},
                { name: 'British Pound' , flagClass:"flag-icon flag-icon-gb fi-xl fi-round"},
                { name: 'Indian Rupee', flagClass: "flag-icon flag-icon-in fi-xl fi-round"},
                { name: 'CFP Franc', flagClass: "flag-icon flag-icon-fr fi-xl fi-round"},
                { name: 'United States Dollar', flagClass: "flag-icon flag-icon-us fi-xl fi-round"},
                { name: 'Deutsche Mark',flagClass: "flag-icon flag-icon-de fi-xl fi-round"},
                { name: 'Euro', flagClass: "flag-icon flag-icon-eu fi-xl fi-round"},
                { name: 'Russian Ruble', flagClass:"flag-icon flag-icon-ru fi-xl fi-round"},
            ],

            bank_name_options: [
                'Reliance Bank Ltd',
                'Sainsburys Bank Plc',
                'TSantander UK Plc  ',
                'Bank Mandiri (Europe) Limited',
            ],

            listof_currency: [
                { name: 'GBP', flagClass: "flag-icon flag-icon-gb fi-xl fi-round"},
                { name: 'GHS', flagClass: "flag-icon flag-icon-gh fi-xl fi-round"},
                { name: 'USD', flagClass: "flag-icon flag-icon-us fi-xl fi-round"},
                { name: 'RUB', flagClass:"flag-icon flag-icon-ru fi-xl fi-round"},
            ],

            payment:{
                payment_email:''
            }
        }
    },methods: {
        singleTransfer(){
            this.$bvModal.show('payment_notification')
        },

        accountFisEmpty(e) {      
            if(e.target.value == ''){
                this.$refs['invalideAccoubtNumber'].show()
            }
        },

        paymentTransfer() {
            this.$bvModal.show('addmoney')
        },
    }
}
</script>
